<template>
<main class="content">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <h3>Individueller Zaun-Konfigurator</h3>
        <small>mit Sofortpreis fur komplette Zaunanlagen.</small>
        <p><span class="badge badge-danger live">Live Vorschau</span></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9">
        <LivePreview />
      </div>
      <div class="col-md-3">
        Control box
      </div>
    </div>
  </div>
  <StepNavigation />
  <Options />
  <div>Construction</div>
</main>
</template>
<script>
import StepNavigation from './common/StepNavigation'
import Options from './common/Options'
import LivePreview from './common/LivePreview.vue'
import axios from 'axios'
import { onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useMeta } from 'vue-meta'
export default {
  components: {
    StepNavigation,
    Options,
    LivePreview
  },
  setup () {
    const store = useStore()
    async function initSystem () {
      const apiKey = btoa(process.env.VUE_APP_API_KEY)
      const apiUrl = process.env.VUE_APP_API_URL
      await axios.post(apiUrl + 'builder', { re: apiKey }).then(resp => {
        if (resp.data && resp.data.definition) {
          store.dispatch('system/setSystem', resp.data.definition)
        }
      })
    }
    useMeta({
      title: 'Loewe-Zaun.de - System'
    })
    onMounted(() => {
      initSystem()
    })
  }
}
</script>
